/** @jsx jsx */
import React from "react" // eslint-disable-line no-unused-vars
import { useStaticQuery, graphql } from "gatsby"
import { jsx, useColorMode } from "theme-ui"

import Breadcrumb from "../../../components/breadcrumb"
import Constrain from "../../../components/constrain"
import Box from "../../../components/box"
import Paragraph from "../../../components/paragraph"
import Inline from "../../../components/inline"
import Heading from "../../../components/heading"
import HoelderlinLiebtNavigation from "../../../content/hoelderlin-liebt/navigation"
import Image from "../../../components/image"
import Layout from "../../../layouts/default"
import PageTitle from "../../../components/page-title"
import Seo from "../../../components/seo"
import Stack from "../../../components/stack"
import VideoPlayer from "../../../components/video-player"

import logoLiLand from "../../../images/partner-logos/liland-bawue.svg"
import logoLiLandWhite from "../../../images/partner-logos/liland-bawue-white.svg"

const HoelderlinLiebtPage = () => {
  const [colorMode] = useColorMode()

  const data = useStaticQuery(
    graphql`
      {
        ogImage: file(relativePath: { eq: "og_images/hoelderlin-liebt.jpg" }) {
          id
          childImageSharp {
            gatsbyImageData(width: 1200, height: 600, layout: FIXED)
          }
        }
        ministerium: file(
          relativePath: {
            eq: "partner-logos/ministerium-soziales-integration.png"
          }
        ) {
          childImageSharp {
            gatsbyImageData(width: 200, layout: CONSTRAINED)
          }
        }
        posterIntro: file(
          relativePath: { eq: "hoelderlin-liebt/poster-intro.jpg" }
        ) {
          ...largeImage
        }
      }
    `
  )

  return (
    <Layout backdrop="hoelderlin-liebt">
      <Seo
        title="Hölderlin liebt"
        description="Der Dichter Friedrich Hölderlin liebt die Wörter. Die schwierigen ganz besonders. Seine Gedichte sind voll von ungewöhnlichen Wörtern. Das macht sie besonders, aber auch schwer zu verstehen. Manche sagen sogar: Es sind die schwierigsten Gedichte in deutscher Sprache. Die Gedichte von Hölderlin sind das Gegenteil von einfach. Das soll sich mit unserer Sonder-Ausstellung im Museum Hölderlinturm nun ändern."
        image={data.ogImage.childImageSharp.gatsbyImageData}
      />
      <Stack space={[12, 24]}>
        <Stack>
          <Breadcrumb
            items={[
              {
                title: "Digital",
                link: "/digital",
              },
              {
                title: "Sonderausstellungen",
                link: "/sonderausstellungen",
              },
              {
                title: "Hölderlin liebt",
                link: "/sonderausstellungen/hoelderlin-liebt",
              },
            ]}
          />
          <PageTitle>Hölderlin liebt…</PageTitle>
          <Heading as="p" level={3}>
            Eine Ausstellung in Einfacher Sprache und Gebärdensprache
          </Heading>
          <Paragraph dropcap={true}>
            Der Dichter Friedrich Hölderlin ist oft verliebt: <br />
            Er liebt die Natur mit ihren hohen Bäumen und weiten Flüssen. <br />
            Er liebt eine Frau mit dem Namen Susette Gontard. <br />
            Und vor allem liebt er die Wörter. <br />
            Er spricht sie laut vor sich hin. <br />
            Er zählt ihre Silben. <br />
            Und er baut daraus Gedichte. <br />
            Die Gedichte sind schwierig. <br />
            Aber sie sind auch schön.
          </Paragraph>

          <Paragraph>
            In dieser digitalen Austellung können Sie <br />
            Friedrich Hölderlin und seine Gedichte <br />
            in Einfacher Sprache und Gebärdensprache kennen lernen.
          </Paragraph>

          <Paragraph>
            Sie erfahren: <br />
            Das ist der Fluss Neckar, der Hölderlin ein Leben lang begleitet
            hat.
            <br />
            Das sind seine Lieblingswörter. <br />
            So duften die Pflanzen aus seinen Gedichten.
            <br />
            So verbringt er seine Tage im Turm. <br />
            So beschreibt er den Sommer und den Winter in seinen Gedichten.
          </Paragraph>

          <VideoPlayer src="hoelderlin-liebt/intro" poster={data.posterIntro} />
        </Stack>
        <HoelderlinLiebtNavigation />
        <Constrain align="left">
          <Stack>
            <Paragraph size={3}>
              Das Projekt ›Hölderlin in Einfacher Sprache‹ wird unterstützt
              durch das Ministerium für Soziales und Integration
              Baden-Württemberg. Die Übersetzungen in Deutsche Gebärdensprache
              wurden gefördert durch die Arbeitsstelle für literarische Museen,
              Archive und Gedenkstätten in Baden-Württemberg.
            </Paragraph>
            <Inline alignY="center" space={12}>
              <Box sx={{ width: 48 }}>
                <Image
                  image={data.ministerium.childImageSharp.gatsbyImageData}
                  alt="Logo Ministerium für Soziales und Integration Baden-Württemberg"
                />
              </Box>
              <img
                src={colorMode === "light" ? logoLiLand : logoLiLandWhite}
                alt="Logo Literaturland Baden-Württemberg"
                width="82"
                height="92"
                sx={{ width: "auto", height: 24 }}
              />
            </Inline>
          </Stack>
        </Constrain>
      </Stack>
    </Layout>
  )
}

export default HoelderlinLiebtPage
